<template>
  <b-modal
    v-model="modalShow"
    size="lg"
    scrollable
    :title="`Statement Details`"
    @hidden="$emit('modal-closed')"
  >
    <div
      v-if="loading"
      class="text-center"
    >
      <b-spinner
        label="Small Spinner"
        variant="primary"
      />
    </div>

    <b-alert
      variant="danger"
      :show="loadingError ? true : false"
    >
      <div class="alert-body">
        <p>
          {{ loadingError }}
        </p>
      </div>
    </b-alert>

    <div v-if="!loading && !loadingError">
      <b-button
        variant="outline-primary"
        class="float-right"
        :disabled="sendingStatementEmail"
        @click="sendStatementsEmail([statementId])"
      >
        <feather-icon
          icon="SendIcon"
          class="mr-1"
        />
        <span class="align-middle">Send Email </span>
        <b-spinner
          v-if="sendingStatementEmail"
          small
          label="Small Spinner"
        />
      </b-button>
      <b-button
        variant="outline-primary"
        class="float-right mr-1"
        :disabled="downloadingStatement"
        @click="
          downloadStatement(
            statementId,
            statementDetails.start_date,
            statementDetails.end_date
          )
        "
      >
        <feather-icon
          icon="DownloadIcon"
          class="mr-1"
        />
        <span class="align-middle">Download Statement </span>
        <b-spinner
          v-if="downloadingStatement"
          small
          label="Small Spinner"
        />
      </b-button>
      <b-table-simple
        bordered
        caption-top
        class="compact-table"
      >
        <caption>
          Statement Details
        </caption>
        <b-tbody>
          <b-tr>
            <b-th>
              Statement ID
            </b-th>
            <b-td colspan="3">
              {{ statementId }}
            </b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Party Name
            </b-th>
            <b-td colspan="3">
              {{ statementDetails.party_identifier }}
            </b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Statement Period
            </b-th>
            <b-td>
              From
              {{ formatedDate(statementDetails.start_date) }} to
              {{ formatedDate(statementDetails.end_date) }}
            </b-td>
            <b-th>
              Due Date
            </b-th>
            <b-td>
              {{ formatedDate(statementDetails.due_date) }}
            </b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Total Amount
            </b-th>
            <b-td>
              {{ formateNumber(statementDetails.total_amount) }}
            </b-td>
            <b-th>
              Paid Amount
            </b-th>
            <b-td>
              {{ formateNumber(statementDetails.paid_amount) }}
            </b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Status
            </b-th>
            <b-td colspan="3">
              <status-icon :value="statementDetails.pay_status" />
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>

      <b-table
        :items="statementDetails.bills"
        :fields="tableFields"
        striped
        hover
        show-empty
        sort-by="bill_no"
        sort-asc
        caption-top
        class="compact-table"
      >
        <template #cell(date)="data">
          {{ formatedDate(data.item.date) }}
        </template>
        <template #cell(pay_status)="data">
          <status-icon :value="data.item.pay_status" />
        </template>
        <template #cell(amount)="data">
          {{ formateNumber(data.item.amount) }}
        </template>
        <template #cell(due_amount)="data">
          {{ formateNumber(data.item.due_amount) }}
        </template>
        <template #table-caption>
          Bill Details
        </template>
      </b-table>

      <b-table
        :items="statementDetails.credit_debit_notes"
        :fields="creditDebitTableField"
        striped
        hover
        sort-by="date"
        sort-asc
        caption-top
        show-empty
        responsive
        class="compact-table"
      >
        <template #cell(date)="data">
          {{ formatedDate(data.item.date) }}
        </template>
        <template #cell(pay_status)="data">
          <status-icon :value="data.item.pay_status" />
        </template>
        <template #cell(amount)="data">
          {{ formateNumber(data.item.amount) }}
        </template>
        <template #cell(due_amount)="data">
          {{ formateNumber(data.item.due_amount) }}
        </template>
        <template #table-caption>
          Credit/Debit Notes
        </template>
      </b-table>
    </div>
    <template #modal-footer>
      <div class="w-100">
        <b-button
          variant="secondary"
          class="float-right"
          @click="modalShow = false"
        >
          Close
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {
  BTable,
  BButton,
  BAlert,
  BSpinner,
  BTableSimple,
  BTbody,
  BTr,
  BTh,
  BTd,
} from 'bootstrap-vue'
import axios from 'axios'

import dateMixin from '@/mixins/dateMixin'
import statementMixin from '@/mixins/statementMixin'
import formatMixin from '@/mixins/formatMixin'
import StatusIcon from '../UI/StatusIcon.vue'

export default {
  components: {
    BTable,
    BTableSimple,
    BTbody,
    BButton,
    BAlert,
    BSpinner,
    BTr,
    BTh,
    BTd,
    StatusIcon,
  },
  mixins: [dateMixin, statementMixin, formatMixin],
  props: {
    statementId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      statementDetails: {},
      modalShow: true,
      loadingError: null,
      loading: true,
      tableFields: [
        { key: 'bill_no', label: 'Bill No' },
        { key: 'date', label: 'Date' },
        {
          key: 'amount',
          label: 'Amount',
          class: 'text-right',
        },
        {
          key: 'due_amount',
          label: 'Due Amount',
          class: 'text-right pr-2',
        },
        { key: 'mode', label: 'Mode' },
        { key: 'pay_status', label: 'Pay Status' },
      ],
      creditDebitTableField: [
        { key: 'doc_no' },
        { key: 'date' },
        { key: 'type' },
        { key: 'amount', class: 'text-right' },
        { key: 'due_amount', class: 'text-right pr-2' },
        { key: 'mode' },
        { key: 'pay_status' },
      ],
    }
  },
  created() {
    this.fetchItemDetails()
  },
  methods: {
    fetchItemDetails() {
      axios
        .get(`/accounts/statement_details/${this.statementId}`)
        .then(res => {
          this.statementDetails = res.data.data
          this.loadingError = null
          this.loading = false
        })
        .catch(error => {
          this.loadingError = error?.response?.data?.error || 'Something went wrong'
          this.loading = false
        })
    },
  },
}
</script>

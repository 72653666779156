import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import download from 'downloadjs'
import dateMixin from '@/mixins/dateMixin'

export default {
  mixins: [dateMixin],
  data() {
    return {
      downloadingStatement: false,
      downloadingStatementId: null,
      sendingStatementEmail: false,
      sendingStatementId: null,
      sendingAllStatementEmail: false,
    }
  },
  methods: {
    downloadStatement(statementId, startDate, endDate) {
      this.downloadingStatement = true
      this.downloadingStatementId = statementId
      axios.get(`/accounts/download_statement/${statementId}`, {
        responseType: 'blob',
      })
        .then(res => {
          const contentType = res.headers['content-type']
          const formatedStartDate = this.formatedDate(startDate, 'DD-MMM-YY')
          const formatedEndDate = this.formatedDate(endDate, 'DD-MMM-YY')
          download(res.data, `Statement-${statementId} (${formatedStartDate} to ${formatedEndDate})`, contentType)
          this.downloadingStatement = false
          this.downloadingStatementId = null
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error?.response?.data?.error || 'Something went wrong!',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          this.downloadingStatement = false
        })
    },
    sendStatementsEmail(statementIds, options) {
      const { isSendAll = false } = options || {}
      this.sendingStatementEmail = true
      if (!isSendAll) {
        this.sendingStatementId = statementIds?.[0] || null
      } else {
        this.sendingAllStatementEmail = true
      }
      axios.post('/accounts/send_statements_email', { statement_ids: statementIds })
        .then(res => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res?.data?.message || 'Request Processed',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.sendingStatementEmail = false
          this.sendingStatementId = null
          this.sendingAllStatementEmail = false
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error?.response?.data?.error || 'Something went wrong!',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          this.sendingStatementEmail = false
          this.sendingAllStatementEmail = false
        })
    },
  },
}

<template>
  <div>
    <div
      v-if="loading"
      class="text-center"
    >
      <b-spinner
        label="Small Spinner"
        variant="primary"
      />
    </div>

    <b-alert
      variant="danger"
      :show="loadingError ? true : false"
    >
      <div class="alert-body">
        <p>
          {{ loadingError }}
        </p>
      </div>
    </b-alert>

    <b-table
      v-if="!loading && !loadingError"
      :items="items"
      :fields="tableFields"
      responsive="sm"
      striped
      hover
      show-empty
      class="compact-table"
      no-local-sorting
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      caption-top
      @sort-changed="sortingChanged"
    >
      <template #cell(bill_no)="{item}">
        <span class="d-flex align-items-center">
          {{ item.bill_no }}
          <span
            v-if="item.is_modified"
            class="is-modified-bill"
          />
        </span>
      </template>
      <template #cell(date)="data">
        {{ formatedDate(data.item.date) }}
      </template>

      <template #cell(pay_status)="data">
        <status-icon
          v-if="data.item.pay_status"
          :value="data.item.pay_status"
        />
      </template>

      <template #cell(bill_attachments)="data">
        <span>
          <span
            v-for="(item, index) in data.item.bill_attachments"
            :key="index"
          >
            <a
              :href="item.file"
              target="_blank"
            >
              <span>View File {{ index + 1 }} </span>
              <span v-if="index != data.item.bill_attachments.length - 1">, </span>
            </a>
          </span>
        </span>
      </template>
      <template #cell(amount)="data">
        {{ formateNumber(data.item.amount) }}
      </template>
      <template #cell(due_amount)="data">
        {{ formateNumber(data.item.due_amount) }}
      </template>
      <template
        v-if="displayTableCaption"
        #table-caption
      >
        Bills
      </template>
    </b-table>
    <detailed-pagination
      v-if="!loading && !loadingError"
      :total-items="totalItems"
      :per-page="perPage"
      :current-page="currentPage"
      @page-changed="(page) => {currentPage = page}"
    />
  </div>
</template>

<script>
import {
  BSpinner, BTable, BAlert, VBTooltip,
} from 'bootstrap-vue'
import axios from 'axios'
import dateMixin from '@/mixins/dateMixin'
import formatMixin from '@/mixins/formatMixin'
import StatusIcon from '../UI/StatusIcon.vue'
import DetailedPagination from '../UI/DetailedPagination.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BSpinner, BTable, BAlert, StatusIcon, DetailedPagination,
  },
  mixins: [dateMixin, formatMixin],
  props: {
    filters: {
      type: Object,
      required: true,
    },
    displayTableCaption: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      loading: true,
      loadingError: null,
      items: [],
      tableFields: [
        { key: 'bill_no', label: 'Bill No.', sortable: true },
        ...(this.$props.filters.isTodayList) ? [{ key: 'party__identifier', label: 'Buyer Name', sortable: true }] : [],
        { key: 'date', label: 'Date', sortable: true },
        {
          key: 'amount', label: 'Amount', sortable: true, class: 'text-right',
        },
        {
          key: 'due_amount', label: 'Due Amount', sortable: true, class: 'text-right pr-2',
        },
        { key: 'mode', label: 'Mode', sortable: true },
        { key: 'pay_status', label: 'Pay Status', sortable: true },
        { key: 'bill_attachments', label: 'Attachments' },
      ],
      openStatementId: null,
      currentPage: 1,
      perPage: 50,
      totalItems: 0,
      sortBy: 'bill_no',
      sortDesc: true,
    }
  },
  watch: {
    filters: {
      handler(newVal, oldVal) {
        if (this.currentPage !== 1) {
          this.currentPage = 1
        } else if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
          this.fetchItems()
        }
      },
      deep: true,
    },
    currentPage() {
      this.fetchItems()
    },
  },
  created() {
    this.fetchItems()
  },
  methods: {
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy
      this.sortDesc = ctx.sortDesc
      this.fetchItems()
    },
    fetchItems() {
      this.loading = true

      const params = {
        ...this.filters,
        page: this.currentPage,
        per_page: this.perPage,
        sort_by: this.sortBy,
        sort_desc: this.sortDesc,
      }

      const queryString = Object.keys(params)
        .map(key => {
          if (params[key] !== null && params[key] !== undefined) {
            if (Array.isArray(params[key])) {
              return params[key].map(value => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`).join('&')
            }
            return `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
          }
          return ''
        })
        .filter(Boolean)
        .join('&')

      axios.get(`/accounts/bills?${queryString}`)
        .then(res => {
          this.items = res.data.results
          this.totalItems = res.data.count
          this.loadingError = null
          this.loading = false
        })
        .catch(error => {
          this.loadingError = error?.response?.data?.error || 'Something went wrong'
          this.loading = false
        })
    },
  },
}
</script>

<template>
  <div>

    <div
      v-if="loading"
      class="text-center"
    >
      <b-spinner
        label="Small Spinner"
        variant="primary"
      />
    </div>
    <b-alert
      variant="danger"
      :show="loadingError ? true : false"
    >
      <div class="alert-body">
        <p>
          {{ loadingError }}
        </p>
      </div>
    </b-alert>

    <b-row v-if="!loading && !loadingError">
      <b-col cols="12">
        <b-alert
          variant="danger"
          :show="details.due ? true : false"
        >
          <div class="alert-body">
            <p>
              <feather-icon
                icon="AlertTriangleIcon"
              />
              <span> This party is in defaulter list</span>
            </p>
          </div>
        </b-alert>
        <b-table-simple
          caption-top
          responsive
        >
          <caption>Party Details</caption>
          <b-tbody>
            <b-tr>
              <b-th>
                Identifer
              </b-th>
              <b-td>
                {{ details.identifier }}
              </b-td>
              <b-th>
                Name
              </b-th>
              <b-td>
                {{ details.name }}
              </b-td>
              <b-th>
                Area
              </b-th>
              <b-td>
                {{ details.area }}
              </b-td>
              <b-th>
                Statement Schedule
              </b-th>
              <b-td>
                {{ details.statement_schedule }}
              </b-td>
              <b-th>
                Total Outstanding
              </b-th>
              <b-td>
                {{ details.total_outstanding }}
              </b-td>
              <b-th>
                Is Due
              </b-th>
              <b-td>
                {{ details.due === true ? 'Yes': 'No' }}
              </b-td>
              <b-th>
                Is Active
              </b-th>
              <b-td>
                {{ details.is_active === true ? 'Yes': 'No' }}
              </b-td>
            </b-tr>

          </b-tbody>
        </b-table-simple>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BAlert, BSpinner, BRow, BCol, BTableSimple, BTbody, BTd, BTr, BTh,
} from 'bootstrap-vue'
import axios from 'axios'

export default {
  components: {
    BAlert,
    BSpinner,
    BRow,
    BCol,
    BTableSimple,
    BTbody,
    BTd,
    BTr,
    BTh,
  },
  props: {
    buyerId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      details: {},
      loadingError: null,
      loading: true,
    }
  },
  watch: {
    buyerId() {
      this.fetchDetails()
    },
  },
  created() {
    this.fetchDetails()
  },
  methods: {
    fetchDetails() {
      this.loadingError = null
      this.loading = true

      axios.get(`/accounts/buyer_ledger/${this.buyerId}`)
        .then(res => {
          this.details = res.data.data
          this.loadingError = null
          this.loading = false
        })
        .catch(error => {
          this.loadingError = error?.response?.data?.error || 'Something went wrong'
          this.loading = false
        })
    },
  },
}
</script>

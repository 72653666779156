<template>
  <div>
    <b-card>
      <div
        v-if="loading"
        class="text-center"
      >
        <b-spinner
          label="Small Spinner"
          variant="primary"
        />
      </div>
      <b-alert
        variant="danger"
        :show="loadingError ? true : false"
      >
        <div class="alert-body">
          <p>
            {{ loadingError }}
          </p>
        </div>
      </b-alert>
      <b-row v-if="!loading && !loadingError">
        <b-col md="6">
          <b-form-group>
            <custom-v-select
              v-model="selected"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="options"
              label="text"
              placeholder="Select Party"
              :clearable="false"
            />
          </b-form-group>
          <span v-if="!loading && !loadingError && !selected.id">
            Select party to view ledger
          </span>
        </b-col>
        <b-col md="3">
          <b-button-group>
            <b-button
              :variant="(mode === 'statements' ? 'primary' : 'outline-primary')"
              @click="setMode('statements')"
            >
              Statement View
            </b-button>
            <b-button
              :variant="(mode === 'bills' ? 'primary' : 'outline-primary')"
              @click="setMode('bills')"
            >
              Bills View
            </b-button>
          </b-button-group>
        </b-col>
      </b-row>

      <div v-if="selected.id">
        <party-ledger-detail
          :buyer-id="selected.id"
        />
        <statements-list
          v-if="mode=='statements'"
          :filters="{parties: [selected.id]}"
        />
        <bills-list
          v-if="mode=='bills'"
          :filters="{party: selected.id}"
          :display-table-caption="true"
        />
      </div>

    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BAlert, BSpinner, BButtonGroup, BButton,
} from 'bootstrap-vue'

import axios from 'axios'
import CustomVSelect from '@/components/UI/CustomVSelect.vue'
import PartyLedgerDetail from '../../components/PartyLedger/PartyLedgerDetails.vue'
import StatementsList from '../../components/PartyLedger/StatementsList.vue'
import BillsList from '../../components/PartyLedger/BillsList.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BAlert,
    BSpinner,
    CustomVSelect,
    PartyLedgerDetail,
    BButtonGroup,
    BButton,
    StatementsList,
    BillsList,
  },
  data() {
    return {
      selected: {},
      options: [],
      loadingError: null,
      loading: true,
      mode: 'statements',
    }
  },
  created() {
    this.fetchOptions()
  },
  methods: {
    fetchOptions() {
      this.loading = true
      axios.get('/accounts/buyers_options')
        .then(res => {
          this.options = res.data.data
          this.loadingError = null
          this.loading = false
        })
        .catch(error => {
          this.loadingError = error?.response?.data?.error || 'Something went wrong'
          this.loading = false
        })
    },
    setMode(mode) {
      this.mode = mode
    },
  },
}
</script>
